import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppStoreRedirectComponent } from '@app/app-store-redirect/app-store-redirect.component';
import { AccountActiveGuardService } from '@app/core/account-active-guard.service';
import { Auth0ErrorGuardService } from '@app/core/auth0-error-guard.service';
import { EnhancedFunctionalityForLimitedAccessUsersGuardService } from '@app/core/enhanced-functionality-for-limited-access-users-guard.service';
import { HomeScreenClaimCodeRoutingGuardService } from '@app/core/home-screen-claim-code-routing-guard.service';
import { LoginGuardService } from '@app/core/login-guard.service';
import { NotLoggedInGuardService } from '@app/core/not-logged-in-guard.service';
import { TosGuardService } from '@app/core/tos-guard.service';
import { RegistrationIncompleteGuardService } from '@app/registration/registration-incomplete-guard.service';
import { UnsignedLegalDocsGuardService } from '@app/registration/unsigned-legal-docs-guard.service';

import { ActiveAppointmentGuardService } from './appointment/active-appointment-guard.service';
import { AppointmentJoinComponent } from './appointment/appointment-join/appointment-join.component';
import { AuthGuardService } from './core/auth-guard.service';
import { DesignComponent } from './core/design/design.component';
import { DevRouteGuardService } from './core/dev-route-guard.service';
import { FeatureFlagGuard } from './core/feature-flag.guard';
import { FeatureFlags } from './core/feature-flags/feature-flags';
import { EmailSentConfirmation } from './core/forgot-password/email-sent/email-sent-confirmation.component';
import { ForgotPassword } from './core/forgot-password/forgot-password.component';
import { LogoutComponent } from './core/logout/logout.component';
import { RegistrationCompleteGuardService } from './core/registration-complete-guard.service';
import { TosInterstitialComponent } from './core/tos/tos-interstitial.component';
import { TrialVideoVisitGuardService } from './core/trial-video-visit-guard.service';
import { GetCareDeepLinkGuard } from './get-care/get-care-deep-link.guard';
import { TrialVideoVisitOnboardingComponent } from './membership/gcn-confirmation/trial-video-visit-onboarding.component';
import { LoggedInWrapperComponent } from './shared/logged-in-wrapper/logged-in-wrapper.component';
import { MfaRegStepComponent } from './shared/mfa-step/mfa-reg-step.component';

const appRoutes: Routes = [
  { path: 'forgot_password', component: ForgotPassword, canActivate: [NotLoggedInGuardService] },
  { path: 'forgot_password/mfa_verification', component: ForgotPassword, canActivate: [NotLoggedInGuardService] },
  { path: 'forgot_password/email_sent', component: EmailSentConfirmation },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService] },
  { path: 'login', canActivate: [LoginGuardService], children: [] },
  // duplicate route for login that does not deep link to native apps
  { path: 'login-web', canActivate: [LoginGuardService], children: [] },
  {
    path: 'login-error/login-with-amazon/linked-account-not-found',
    redirectTo: '/registration/error?errorCode=logging_in_with_amazon_without_linked_patient_account',
  },
  {
    path: 'terms-of-service',
    component: TosInterstitialComponent,
    canActivate: [AuthGuardService, UnsignedLegalDocsGuardService],
  },
  {
    path: 'mfa',
    component: MfaRegStepComponent,
    canActivate: [AuthGuardService, RegistrationIncompleteGuardService],
  },
  // auth required pages
  {
    path: '',
    component: LoggedInWrapperComponent,
    // NOTE: If you need to add guards to top level, make sure to handle logged out case by returning false - see guards
    // already in place for examples. Otherwise subsequent guards may get errors from 1life leading to duplicative redirect
    // to login, leading to inconsistent / dropped / unexpected redirect to attempted path after login
    canActivate: [
      Auth0ErrorGuardService,
      AuthGuardService,
      TrialVideoVisitGuardService,
      RegistrationCompleteGuardService,
      TosGuardService,
    ],
    children: [
      {
        path: 'appointments/:id/join',
        component: AppointmentJoinComponent,
        canActivate: [ActiveAppointmentGuardService],
      },
      {
        path: '',
        canActivate: [HomeScreenClaimCodeRoutingGuardService],
        children: [
          {
            path: '',
            loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule),
            canActivate: [AccountActiveGuardService, EnhancedFunctionalityForLimitedAccessUsersGuardService],
          },
        ],
      },
      {
        path: 'account',
        loadChildren: () => import('@app/account/account.module').then(m => m.AccountModule),
      },
      {
        path: 'help',
        loadChildren: () => import('@app/help-center/help-center.module').then(m => m.HelpCenterModule),
      },
      {
        path: 'guest',
        loadChildren: () => import('@app/guest/guest.module').then(m => m.GuestModule),
      },
      {
        path: 'appointments',
        loadChildren: () => import('@app/appointment/appointment.module').then(m => m.AppointmentModule),
        canActivate: [EnhancedFunctionalityForLimitedAccessUsersGuardService],
      },
      {
        path: 'billing',
        loadChildren: () => import('@app/billing/billing.module').then(m => m.BillingModule),
      },
      {
        path: 'health-record',
        loadChildren: () => import('@app/health-record/health-record.module').then(m => m.HealthRecordModule),
      },
      {
        path: 'amazon-pharmacy',
        loadChildren: () => import('@app/amazon-pharmacy/apee.module').then(m => m.AmazonExpeditedEnrollmentModule),
      },
      {
        path: 'login-with-amazon',
        loadChildren: () =>
          import('@app/login-with-amazon/login-with-amazon.module').then(m => m.LoginWithAmazonModule),
      },
      {
        path: 'lab-results',
        redirectTo: 'health-record/lab-results',
        pathMatch: 'prefix',
      },
      {
        path: 'membership',
        loadChildren: () => import('@app/membership/membership.module').then(m => m.MembershipModule),
      },
      {
        path: 'messages',
        loadChildren: () => import('@app/message/message.module').then(m => m.MessageModule),
      },
      {
        path: 'pharmacy',
        loadChildren: () => import('@app/pharmacy/pharmacy.module').then(m => m.PharmacyModule),
        canActivate: [AccountActiveGuardService, EnhancedFunctionalityForLimitedAccessUsersGuardService],
      },
      {
        path: 'prescription',
        loadChildren: () => import('@app/prescription/prescription.module').then(m => m.PrescriptionModule),
        canActivate: [AccountActiveGuardService, EnhancedFunctionalityForLimitedAccessUsersGuardService],
      },
      {
        path: 'programs',
        loadChildren: () => import('@app/program/program.module').then(m => m.ProgramModule),
        canActivate: [FeatureFlagGuard],
        data: {
          featureFlag: FeatureFlags.BHX_MEMBER_PROGRAM_EXPERIENCE,
          redirectTo: '/',
        },
      },
      {
        path: 'providers',
        loadChildren: () => import('@app/providers/providers.module').then(m => m.ProvidersModule),
        canActivate: [EnhancedFunctionalityForLimitedAccessUsersGuardService],
      },
      {
        path: 'survey',
        loadChildren: () => import('@app/survey/survey.module').then(m => m.SurveyModule),
      },
      {
        path: 'care-plan',
        loadChildren: () => import('@app/task/task.module').then(m => m.TaskModule),
      },
      {
        path: 'appointment-booking-sessions',
        redirectTo: 'appointments/appointment-booking-sessions',
        pathMatch: 'full',
      },
      {
        path: 'get-care',
        canActivate: [GetCareDeepLinkGuard],
        loadComponent: () =>
          import('./get-care/mobile-forwarding/mobile-forwarding.component').then(mod => mod.MobileForwardingComponent),
      },
      {
        path: 'health-information-release',
        loadChildren: () =>
          import('@app/health-information-release/health-information-release.module').then(
            m => m.HealthInformationReleaseModule,
          ),
      },
    ],
  },
  { path: 'profile', redirectTo: '/health-record/vitals/blood-pressure', pathMatch: 'full' },
  {
    path: 'profile/blood-pressure',
    redirectTo: '/health-record/vitals/blood-pressure',
    pathMatch: 'full',
  },
  {
    path: 'registration',
    loadChildren: () => import('@app/registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'register/expedited',
    loadChildren: () =>
      import('@app/registration/expedited/expedited-registration.module').then(m => m.ExpeditedRegistrationModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('@app/registration/consumer/consumer-registration.module').then(m => m.ConsumerRegistrationModule),
  },
  {
    path: 'registration/limited_access',
    loadComponent: () =>
      import('@app/registration/limited-access/limited-access-enrollment-landing-page.component').then(
        mod => mod.LimitedAccessEnrollmentLandingPageComponent,
      ),
  },
  {
    path: 'onboarding',
    loadChildren: () => import('@app/onboarding/onboarding.module').then(m => m.OnboardingModule),
  },
  {
    path: 'trial-video-visit-onboarding',
    component: TrialVideoVisitOnboardingComponent,
  },
  {
    path: 'gift',
    loadChildren: () => import('@app/gift/gift.module').then(m => m.GiftModule),
  },
  { path: 'app-store-redirect', component: AppStoreRedirectComponent },
  {
    path: 'design',
    component: DesignComponent,
    canActivate: [DevRouteGuardService],
  },
  // Provides a way for external links on mobile devices to open in browser instead of the app
  // For iOS this is handled by an entry in src/.well-known/apple-app-site-association
  // For Android this is handled by the app, which includes /appointments/*
  {
    path: 'shift-redirect',
    // Omitting leading slash and using pathMatch full to preserve query params
    redirectTo: 'appointments/shift',
    pathMatch: 'full',
  },
  {
    path: 'mindset-redirect',
    redirectTo: 'appointments/mindset',
    pathMatch: 'full',
  },
  {
    path: 'tasks',
    redirectTo: 'care-plan/action-items',
    pathMatch: 'prefix',
  },
  { path: 'after-visit-summaries', redirectTo: 'care-plan/after-visit-summaries', pathMatch: 'full' },
  { path: 'after-visit-summaries', redirectTo: 'care-plan/after-visit-summaries', pathMatch: 'prefix' },

  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
